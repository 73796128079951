<template>
  <b-container>
    <!-- <h2 class="mb-3">{{ $t('Academia') }}</h2> -->
    <b-row>
      <b-col v-for="video in informativos" :key="video.id" cols="12" md="6" lg="4" class="mb-4">
        <b-card :title="$t(video.title)" class="h-100">
          <div class="video-container">
            <iframe
              :src="video.url"
              class="video-iframe"
              title="Vídeo Informativo"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <b-card-text class="mt-2" v-if="video.description">{{ $t(video.description) }}</b-card-text>
        </b-card>
      </b-col>
    </b-row>    
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      informativos: [
        {
          id: 1,
          title: 'Como cadastrar na New X Coins - PT-BR',
          url: 'https://www.youtube.com/embed/31C5ylcLaZU',
          description: null
        },
        {
          id: 2,
          title: 'Como sacar na New X Coins - PT-BR',
          url: 'https://www.youtube.com/embed/gbX2i7m6Zzs',
          description: null
        },
        {
          id: 3,
          title: 'Plano de negócio New X Coins - PT-BR',
          url: 'https://www.youtube.com/embed/v8t2gm5WUZo',
          description: null
        },
        {
          id: 4,
          title: 'New X Coins English Presentation',
          url: 'https://www.youtube.com/embed/6Xwwp_4tcfQ',
          description: null
        },
        {
          id: 5,
          title: 'Registro en New X Coins por Celular',
          url: 'https://www.youtube.com/embed/N2HCpVUnKl8',
          description: null
        },
        {
          id: 6,
          title: 'Compra de Licencia en X Coins por Binance',
          url: 'https://www.youtube.com/embed/0W8I500XIgE',
          description: null
        },
        {
          id: 7,
          title: 'Presentación New X Coins',
          url: 'https://www.youtube.com/embed/h33lq9eGeyc',
          description: null
        },
        {
          id: 8,
          title: 'Retirando de XCoins a Binance',
          url: 'https://www.youtube.com/embed/uzOeSG9Z2Ls',
          description: null
        },
        {
          id: 9,
          title: 'BackOffice New X Coins',
          url: 'https://www.youtube.com/embed/_9RwX-Yj5ws',
          description: null
        },
        {
          id: 10,
          title: 'Registro en New X Coins por Computadora',
          url: 'https://www.youtube.com/embed/XiUANJwXYfo',
          description: null
        },
      ]
    };
  }
};
</script>

<style scoped>
.my-4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 12px;
}
</style>
